<template>
    <header class="bg-[#3f5864]">
      <div class="container max-w-5xl mx-auto p-3 flex justify-between items-center">
        <div class="flex gap-5 items-center">
          <img src="/images/logo.png" alt="Miljøservice logo" class="max-h-12">
          <h1 class="text-white text-xl italic">Kundeportal</h1>
        </div>

        <button class="text-white bg-[#5fbb3a] p-2" @click="signOut({callbackUrl: '/login', external: true})" v-if="status === 'authenticated'">
          Log ud
        </button>
      </div>
    </header>

    <main class="container max-w-5xl mx-auto pt-8">
        <slot/>
    </main>
</template>

<script lang="ts" setup>
const { signOut, status } = useAuth();
</script>